<template>
  <div>
    <div id="global-markets-page">
      <h1 class="h1-title">Live Pricing</h1>
      <div v-if="!(currentDealer.has_autograb_access || currentDealership.is_autograb_account)">
        <div class="flex-column horizontal-flex-center margin-bottom-4 margin-top-4">
          <img class="splash-img horizontal-flex-center" src="/assets/img/splash5.jpg" />
          <h2 class="h2-title margin-top-1-05 horizontal-flex-center">Welcome To Live Pricing</h2>
          <p class="body-1 margin-top-05 horizontal-flex-center">
            You don't seem to be setup with the iAppraise Live Pricing system. Contact Us to learn more.
          </p>
        </div>
      </div>
      <div v-if="currentDealer.has_autograb_access || currentDealership.is_autograb_account">
        <div class="flex-row position-relative margin-top-2">
          <div class="position-relative">
            <secondary-button
              icon="assets/img/icon-plus.svg"
              title="Request Live Pricing on Vehicle"
              v-on:click="showRequestNew = !showRequestNew"
              v-if="currentDealer.is_manager"
            />
            <div v-if="showRequestNew" class="dropdown shadow body-2 padding-vertical-025">
              <div
                v-on:click="
                  requestingNewGlobalMarket = 0;
                  showRequestNew = false;
                "
                class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
              >
                Rego
              </div>
              <div
                v-on:click="
                  requestingNewGlobalMarket = 1;
                  showRequestNew = false;
                "
                class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
              >
                VIN
              </div>
              <div
                v-on:click="
                  requestingNewGlobalMarket = 2;
                  showRequestNew = false;
                "
                class="padding-vertical-05 padding-horizontal pointer-cursor highlight-on-hover flex-row"
              >
                Make/Model
              </div>
            </div>
          </div>
          <div class="position-relative">
            <secondary-button
              class="margin-left"
              icon="assets/img/icon-plus.svg"
              :title="
                requestingNewCarAnaylsisReport
                  ? 'Requesting. This can take up to 30 seconds...'
                  : 'Request Car Analysis Report on VIN'
              "
              v-on:click="showRequestReport = !showRequestReport"
            />
            <input-drop-box
              v-if="showRequestReport"
              title="VIN"
              @onClose="showRequestReport = false"
              @onSubmit="requestCarAnalysisVIN"
            />
          </div>
          <search-bar
            class="margin-right margin-left-1-05 flex-grow"
            availableFilterMode=""
            placeholder="Search by vehicle"
            :emitActions="true"
            @applyFilter="applyFilter"
          />
        </div>
        <table class="results-table margin-top">
          <tr class="header">
            <th>Vehicle</th>
            <th>Date Requested</th>
            <th>Competitors</th>
            <th>Retail Price</th>
            <th>Trade Price</th>
            <th v-if="has_autograb_vr_access">Car Analysis Report</th>
            <th>Appraisal</th>
          </tr>
          <tr class="data-row selectable" v-for="agfd in filteredAGFDs()" v-bind:key="agfd.id">
            <td v-on:click="goToGlobalMarket(agfd)">{{ agfd.title ? agfd.title : "-" }}</td>
            <td v-on:click="goToGlobalMarket(agfd)">{{ formatDate(agfd.date_requested) }}</td>
            <td v-on:click="goToGlobalMarket(agfd)">{{ agfd.competitors }}</td>
            <td v-on:click="goToGlobalMarket(agfd)">
              {{ agfd.retail_price ? "$" + Number(agfd.retail_price).toLocaleString() : "No Pricing Data" }}
            </td>
            <td v-on:click="goToGlobalMarket(agfd)">
              {{ agfd.trade_price ? "$" + Number(agfd.trade_price).toLocaleString() : "No Pricing Data" }}
            </td>
            <td v-if="has_autograb_vr_access && agfd.is_vin_rego_validated">
              <div class="flex-row">
                <secondary-button
                  v-on:click="requestGlobalMarketReport(agfd)"
                  :title="agfd.vehicle_report_pdf_generating ? 'Generating...' : 'Car Analysis Report'"
                  :disabled="agfd.vehicle_report_pdf_generating"
                />
                <secondary-button
                  v-on:click="openNewWindow(agfd.latest_report.vehicle_report_pdf_url)"
                  class="margin-left-05"
                  title="View Latest Report"
                  v-if="agfd.latest_report && agfd.latest_report.vehicle_report_pdf_url"
                />
              </div>
              <p class="error margin-top-025" v-if="agfd.vehicle_report_pdf_generating">
                This can take up to 30 seconds.
              </p>
            </td>
            <td v-if="!agfd.is_vin_rego_validated && has_autograb_vr_access">
              <secondary-button :disabled="true" title="Request Report" />
            </td>
            <td v-on:click="goToForm(agfd)">
              <secondary-button :title="agfd.form ? 'Open Appraisal' : 'No Appraisal'" :disabled="!agfd.form" />
            </td>
          </tr>
        </table>

        <paginator-bar
          v-model:page="page"
          :pageBounds="pageBounds()"
          :loadedItemsCount="agfds.length"
          :loading="loading"
          :hasMorePages="nextUrl != null"
          :totalItemsCount="agfdCount"
          @loadMore="getNextAGFDs()"
        />

        <new-global-market-request-modal
          v-if="requestingNewGlobalMarket != null"
          :loading="loadingRequestNewGlobalMarket"
          :inputType="requestingNewGlobalMarket"
          @cancel="requestingNewGlobalMarket = null"
          @requestGlobalMarketRego="requestGlobalMarketRego"
          @requestGlobalMarketVIN="requestGlobalMarketVIN"
          @requestGlobalMarketID="requestGlobalMarketID"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import {
  fetchAGFDs,
  requestAGFDRego,
  requestAGFDVIN,
  requestAGFDID,
  requestAutoGrabVehicleReport,
  requestVehicleReportVIN
} from "../../../api/GlobalMarket.js";
import { axiosInstance } from "../../../api/axiosHandler";
import { pageBounds } from "../../../helpers/v2/utilityHelpers";

import NewGlobalMarketRequestModal from "../../../components/Modals/v2/NewGlobalMarketRequestModal.vue";
import SearchBar from "../../../components/Views/v2/SearchBar.vue";
import SecondaryButton from "../../../components/Buttons/v2/SecondaryButton.vue";
import PaginatorBar from "../../../components/Common/PaginatorBar.vue";
import InputDropBox from "../../../components/Common/InputDropBox.vue";

export default {
  name: "GlobalMarkets",
  components: { SearchBar, SecondaryButton, PaginatorBar, NewGlobalMarketRequestModal, InputDropBox },
  data: function () {
    return {
      searchDict: {
        title: null,
        date_gt: null,
        date_lt: null
      },
      showRequestNew: false,
      showRequestReport: false,
      page: 1,
      agfds: [],
      agfdCount: 0,
      axiosCancelToken: null,
      nextUrl: null,
      loading: false,
      requestingNewGlobalMarket: null,
      requestingNewCarAnaylsisReport: false,
      loadingRequestNewGlobalMarket: false
    };
  },
  methods: {
    pageBounds() {
      return pageBounds(this.page);
    },
    filteredAGFDs: function () {
      let bounds = this.pageBounds();
      var toReturn = this.agfds;

      return toReturn.slice(bounds.lowBound, bounds.highBound);
    },
    fetchAGFDs: function () {
      if (this.axiosCancelToken) {
        if (this.axiosCancelToken.cancel) {
          this.axiosCancelToken.cancel("New Request");
        }
      }
      this.axiosCancelToken = axios.CancelToken.source();
      this.loading = true;
      fetchAGFDs(this.searchDict, this.axiosCancelToken)
        .then(response => {
          this.loading = false;
          this.agfds = response.results;
          this.agfdCount = response.count;
          this.nextUrl = response.next;
          this.page = 1;
        })
        .catch(error => {
          this.loading = false;
          if (!error.__CANCEL__) {
            this.addError(error);
          }
        });
    },
    goToGlobalMarket: function (agfd) {
      if (!this.currentDealer.is_manager) {
        alert("Sorry! Only managers have permission to view this page");
        return;
      }
      if (agfd.form) {
        let route = this.$router.resolve({
          name: "GlobalMarket",
          params: { formId: agfd.form }
        });
        window.open(route.href, "_blank");
      } else {
        let route = this.$router.resolve({
          name: "GlobalMarketFormless",
          params: { agfdId: agfd.id }
        });
        window.open(route.href, "_blank");
      }
    },
    goToForm: function (agfd) {
      if (agfd.form) {
        let routeData = this.$router.resolve({
          name: "Form",
          params: { id: agfd.form }
        });
        window.open(routeData.href, "_blank");
      }
    },
    requestGlobalMarketRego: function (searchDict) {
      if (this.loadingRequestNewGlobalMarket) {
        return;
      }
      this.loadingRequestNewGlobalMarket = true;
      requestAGFDRego(searchDict)
        .then(agfd => {
          this.loadingRequestNewGlobalMarket = false;
          this.agfds.unshift(agfd);
          this.requestingNewGlobalMarket = null;
          let route = this.$router.resolve({
            name: "GlobalMarketFormless",
            params: { agfdId: agfd.id }
          });
          window.open(route.href, "_blank");
        })
        .catch(error => {
          this.addError(error);
          this.loadingRequestNewGlobalMarket = false;
        });
    },
    requestGlobalMarketVIN: function (searchDict) {
      if (this.loadingRequestNewGlobalMarket) {
        return;
      }
      this.loadingRequestNewGlobalMarket = true;
      requestAGFDVIN(searchDict)
        .then(agfd => {
          this.loadingRequestNewGlobalMarket = false;
          this.agfds.unshift(agfd);
          this.requestingNewGlobalMarket = null;
          let route = this.$router.resolve({
            name: "GlobalMarketFormless",
            params: { agfdId: agfd.id }
          });
          window.open(route.href, "_blank");
        })
        .catch(error => {
          this.addError(error);
          this.loadingRequestNewGlobalMarket = false;
        });
    },
    requestCarAnalysisVIN: function (vin) {
      this.showRequestReport = false;
      if (this.requestingNewCarAnaylsisReport) {
        return;
      }
      this.requestingNewCarAnaylsisReport = true;
      requestVehicleReportVIN(vin)
        .then(agfd => {
          this.requestingNewCarAnaylsisReport = false;
          this.agfds.unshift(agfd);
        })
        .catch(error => {
          this.requestingNewCarAnaylsisReport = false;
          this.addError(error);
          this.loadingRequestNewGlobalMarket = false;
        });
    },
    requestGlobalMarketID: function (searchDict) {
      if (this.loadingRequestNewGlobalMarket) {
        return;
      }
      this.loadingRequestNewGlobalMarket = true;
      requestAGFDID(searchDict.vehicle.id, searchDict.mileage, searchDict.vehicle.title)
        .then(agfd => {
          this.loadingRequestNewGlobalMarket = false;
          this.agfds.unshift(agfd);
          this.requestingNewGlobalMarket = null;
          let route = this.$router.resolve({
            name: "GlobalMarketFormless",
            params: { agfdId: agfd.id }
          });
          window.open(route.href, "_blank");
        })
        .catch(error => {
          this.addError(error);
          this.loadingRequestNewGlobalMarket = false;
        });
    },
    requestGlobalMarketReport: function (agfd) {
      if (agfd.vehicle_report_pdf_generating) {
        return;
      }
      agfd.vehicle_report_pdf_generating = true;
      requestAutoGrabVehicleReport(agfd.id)
        .then(response => {
          agfd.vehicle_report_pdf_generating = false;
          agfd.latest_report = response.latest_report;
          this.openNewWindow(agfd.latest_report.vehicle_report_pdf_url);
        })
        .catch(error => {
          agfd.vehicle_report_pdf_generating = false;
          this.addError(error);
          this.loadingRequestNewGlobalMarket = false;
        });
    },
    openNewWindow: function (url) {
      window.open(url, "_blank");
    },
    formatDate(dateString) {
      let convertedDate = new Date(dateString);
      return convertedDate.toDateString() + ", " + convertedDate.toLocaleTimeString();
    },
    clearFilters: function () {
      this.searchDict.title = null;
      this.searchDict.date_gt = null;
      this.searchDict.date_lt = null;
    },
    getNextAGFDs() {
      if (this.nextUrl) {
        if (this.loading) {
          return;
        }
        this.loading = true;
        axiosInstance()
          .get(this.nextUrl, null)
          .then(response => {
            this.loading = false;
            this.nextUrl = response.data.next;
            response.data.results.forEach(agfd => {
              this.agfds.push(agfd);
            });
          })
          .catch(error => {
            this.loading = false;
            this.addError(error);
          });
      }
    },
    applyFilter: function (filter) {
      if (filter.filter.key == "quick_search") {
        this.searchDict.title = filter.filter.value;
      }
    },
    removeFilter: function (filter) {
      delete this.filters[filter.key];
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  computed: {
    has_autograb_vr_access: function () {
      return this.currentDealership.is_autograb_vr_account || this.currentDealer.has_autograb_vr_access;
    },
    ...mapState({
      currentDealership: state => state.dealershipStore.currentDealership,
      currentDealer: state => state.dealershipStore.currentDealer
    })
  },
  watch: {
    searchDict: {
      handler: function () {
        this.fetchAGFDs();
      },
      deep: true
    }
  },
  mounted: function () {
    if (this.currentDealer.has_autograb_access || this.currentDealership.is_autograb_account) {
      this.fetchAGFDs();
      this.getNextAGFDs();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#global-markets-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}

.splash-img {
  width: 326px;
  height: 170px;
  object-fit: cover;
}

.highlight-on-hover:hover {
  background-color: $BACKGROUND_HOVER_COLOR;
}
</style>
