<template>
  <div>
    <h6 v-if="vehicles.length <= 0">There don't seem to be any found matches.</h6>
    <table v-if="vehicles.length > 0" class="results-table">
      <tr class="header">
        <th>Vehicle</th>
      </tr>
      <tr
        v-for="vehicle in sortedVehicles()"
        v-bind:key="vehicle.id"
        class="data-row selectable"
        v-on:click="selectedVehicle(vehicle)"
        @mouseover="hoveringVehicle = vehicle"
        @mouseleave="hoveringVehicle = null"
      >
        <td>
          <p class="title-label">{{ vehicle.title }}</p>
          <p class="detail-label">
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.series != vehicle.series }">{{
              vehicle.series
            }}</span>
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.num_gears != vehicle.num_gears }"
              >{{ vehicle.num_gears }}sp</span
            >
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.num_cylinders != vehicle.num_cylinders }"
              >{{ vehicle.num_cylinders }}cyl</span
            >
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.transmission_type != vehicle.transmission_type }">{{
              vehicle.transmission_type
            }}</span>
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.body_type != vehicle.body_type }">{{
              vehicle.body_type
            }}</span>
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.drive_type != vehicle.drive_type }">{{
              vehicle.drive_type
            }}</span>
          </p>
          <p class="detail-label">
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.capacity_cc != vehicle.capacity_cc }"
              >{{ vehicle.capacity_cc }}cc</span
            >
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.power_kw != vehicle.power_kw }"
              >{{ vehicle.power_kw }}kw</span
            >
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.torque_nm != vehicle.torque_nm }"
              >{{ vehicle.torque_nm }}nm</span
            >
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.fuel_type != vehicle.fuel_type }">{{
              vehicle.fuel_type
            }}</span>
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.engine_type != vehicle.engine_type }">{{
              vehicle.engine_type
            }}</span>
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.num_doors != vehicle.num_doors }"
              >{{ vehicle.num_doors }}DR</span
            >
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.num_seats != vehicle.num_seats }"
              >{{ vehicle.num_seats }}SEAT</span
            >
          </p>
          <p class="detail-label">
            Released:
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.release_month != vehicle.release_month }">{{
              mapMonthNumberToMonth(vehicle.release_month)
            }}</span>
            <span :class="{ dif: hoveringVehicle && hoveringVehicle.release_year != vehicle.release_year }">{{
              vehicle.release_year
            }}</span>
          </p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { monthArray } from "../../../helpers/v2/utilityHelpers.js";

export default {
  name: "AutograbVehiclesTable",
  props: {
    vehicles: {
      type: Array
    }
  },
  data: function () {
    return {
      hoveringVehicle: null
    };
  },
  methods: {
    selectedVehicle(vehicle) {
      this.$emit("selectedVehicle", vehicle);
    },
    mapMonthNumberToMonth(month) {
      return monthArray[month];
    },
    sortedVehicles() {
      if (this.vehicles) {
        let sortedVehicles = this.vehicles;
        return sortedVehicles.sort((a, b) => b.release_year - a.release_year);
      } else {
        return [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

p {
  margin-bottom: 4px;
}

span {
  margin-right: 4px;
}

.dif {
  color: blue;
  font: normal 14px $FONT_SF_PRO_DISPLAY_SEMI_BOLD;
}
</style>
