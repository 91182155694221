<template>
  <base-modal-view title="Live Pricing" :wide="inputType == 2 && vehicles.length > 0" @cancel="cancelClicked">
    <form class="flex-column" v-if="inputType == 0" @submit.prevent="submitClicked">
      <label class="text-field-title margin-top">Vehicle registration number</label>
      <div class="flex-row margin-top-025">
        <input
          v-model="searchDictRego.rego"
          class="text-field flex-grow"
          type="text"
          placeholder="Registration"
          required
        />
        <select v-model="searchDictRego.state" class="dropdown-field" placeholder="State" required>
          <option value="">-- Select State --</option>
          <option value="SA">SA</option>
          <option value="NSW">NSW</option>
          <option value="VIC">VIC</option>
          <option value="QLD">QLD</option>
          <option value="WA">WA</option>
          <option value="NT">NT</option>
          <option value="TAS">TAS</option>
          <option value="ACT">ACT</option>
        </select>
      </div>
      <label class="text-field-title margin-top-075 margin-bottom-025">Travelled (in km)</label>
      <div class="flex-row">
        <input
          v-model="searchDictRego.mileage"
          class="text-field flex-grow"
          type="number"
          placeholder="Travelled (in km)"
          required
        />
        <p class="text-field-title margin-top-auto margin-bottom-025 margin-left-025">km</p>
      </div>
      <primary-button class="margin-top-1-05" :title="loading ? 'Fetching...' : 'Submit'" />
    </form>

    <form class="flex-column" v-if="inputType == 1" @submit.prevent="submitClicked">
      <label class="text-field-title margin-top-075 margin-bottom-025">VIN</label>
      <div class="flex-row">
        <input v-model="searchDictVIN.vin" class="text-field flex-grow" type="text" placeholder="VIN" required />
      </div>
      <label class="text-field-title margin-top-075 margin-bottom-025">Travelled (in km)</label>
      <div class="flex-row">
        <input
          v-model="searchDictVIN.mileage"
          class="text-field flex-grow"
          type="number"
          placeholder="Travelled (in km)"
          required
        />
        <p class="text-field-title margin-top-auto margin-bottom-025 margin-left-025">km</p>
      </div>
      <primary-button class="margin-top-1-05" :title="loading ? 'Fetching...' : 'Submit'" />
    </form>

    <div v-if="inputType == 2" class="flex-row flew-grow" style="overflow: hidden">
      <div class="flex-grow flex-column margin-right" style="margin-bottom: auto">
        <label class="text-field-title margin-top-075 margin-bottom-025">Model Year</label>
        <input v-model="searchDictMakeModel.year" class="text-field flex-grow" type="number" placeholder="Year" />
        <label class="text-field-title margin-top-075 margin-bottom-025">Travelled (in km)</label>
        <div class="flex-row">
          <input
            v-model="searchDictMakeModel.mileage"
            class="text-field flex-grow"
            type="number"
            placeholder="Mileage"
          />
          <p class="text-field-title margin-top-auto margin-bottom-025 margin-left-025">kms</p>
        </div>
        <label v-if="facetMakes.length > 0" class="text-field-title margin-top-075 margin-bottom-025">Make</label>
        <select class="dropdown-field" v-model="searchDictMakeModel.make" v-if="facetMakes.length > 0">
          <option value="">-- Select a Make --</option>
          <option v-for="make in facetMakes" :value="make.value" v-bind:key="make.value">{{ make.value }}</option>
        </select>
        <label v-if="facetModels.length > 0" class="text-field-title margin-top-075 margin-bottom-025">Model</label>
        <select class="dropdown-field" v-model="searchDictMakeModel.model" v-if="facetModels.length > 0">
          <option value="">-- Select a Model --</option>
          <option v-for="model in facetModels" :value="model.value" v-bind:key="model.value">{{ model.value }}</option>
        </select>
        <label v-if="facetBadges.length > 0" class="text-field-title margin-top-075 margin-bottom-025">Badge</label>
        <select class="dropdown-field" v-model="searchDictMakeModel.badge" v-if="facetBadges.length > 0">
          <option value="">-- Select a Badge --</option>
          <option v-for="badge in facetBadges" :value="badge.value" v-bind:key="badge.value">{{ badge.value }}</option>
        </select>
        <label v-if="facetSeriess.length > 0" class="text-field-title margin-top-075 margin-bottom-025">Series</label>
        <select class="dropdown-field" v-model="searchDictMakeModel.series" v-if="facetSeriess.length > 0">
          <option value="">-- Select a Series --</option>
          <option v-for="series in facetSeriess" :value="series.value" v-bind:key="series.value">
            {{ series.value }}
          </option>
        </select>
        <label v-if="facetTransmission.length > 0" class="text-field-title margin-top-075 margin-bottom-025"
          >Transmission</label
        >
        <select class="dropdown-field" v-model="searchDictMakeModel.transmission" v-if="facetTransmission.length > 0">
          <option value="">-- Select a Transmission --</option>
          <option v-for="transmission in facetTransmission" :value="transmission.value" v-bind:key="transmission.value">
            {{ transmission.value }}
          </option>
        </select>
        <label v-if="facetBodyTypes.length > 0" class="text-field-title margin-top-075 margin-bottom-025"
          >Body Type</label
        >
        <select class="dropdown-field" v-model="searchDictMakeModel.body_type" v-if="facetBodyTypes.length > 0">
          <option value="">-- Select a Body Type --</option>
          <option v-for="body_type in facetBodyTypes" :value="body_type.value" v-bind:key="body_type.value">
            {{ body_type.value }}
          </option>
        </select>
        <primary-button
          title="Try again..."
          v-if="retryFetch"
          class="vertical-spacing-top-half"
          v-on:click="retryRequest()"
        />
      </div>
      <div class="flex-grow flex-column margin-left" style="overflow: scroll; margin-top: 16px">
        <p v-if="vehicles.length <= 0 && !loadingVehicles && !fetchError" class="flex-center">
          No vehicles found. Select at least a Year, Make, Model and Mileage to begin searching.
        </p>
        <p v-if="loadingVehicles" class="flex-center">Loading Vehicles.</p>
        <autograb-vehicles-table
          v-if="vehicles.length > 0 && !loadingVehicles && !loading"
          :vehicles="vehicles"
          @selectedVehicle="selectedVehicle"
        />
        <p v-if="loading" class="flex-center">Fetching...</p>
        <p v-if="fetchError" class="flex-center">{{ fetchError.response.data.message }}</p>
      </div>
    </div>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import AutograbVehiclesTable from "../../Views/v2/AutograbVehiclesTable.vue";

import { requestAGFacet, searchAGWithFacets } from "../../../api/GlobalMarket";

export default {
  name: "NewGlobalMarketRequestModal",
  components: { PrimaryButton, BaseModalView, AutograbVehiclesTable },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    inputType: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      searchDictRego: {
        rego: "",
        state: "",
        mileage: ""
      },
      searchDictVIN: {
        vin: "",
        mileage: ""
      },
      searchDictMakeModel: {
        year: "",
        mileage: "",
        make: "",
        model: "",
        badge: "",
        series: "",
        transmission: "",
        body_type: ""
      },
      facetMakes: [],
      facetModels: [],
      facetBadges: [],
      facetSeriess: [],
      facetTransmission: [],
      facetBodyTypes: [],
      loadingVehicles: false,
      vehicles: [],
      retryFetch: "",
      retryFetchArray: "",
      fetchError: null
    };
  },
  methods: {
    submitClicked: function () {
      if (this.inputType == 0) {
        this.$emit("requestGlobalMarketRego", this.searchDictRego);
      } else if (this.inputType == 1) {
        this.$emit("requestGlobalMarketVIN", this.searchDictVIN);
      }
    },
    calculateMakeModelExtras: function () {
      if (Object.keys(this.searchDictMakeModel).length <= 0) {
        return "";
      }
      var extras = "";

      Object.keys(this.searchDictMakeModel).forEach(key => {
        if (this.searchDictMakeModel[key]) {
          if (extras.length > 0) {
            extras += "&";
          }
          extras += key + "=" + this.searchDictMakeModel[key];
        }
      });
      return extras;
    },
    searchForVehicles: function () {
      this.fetchError = null;
      this.vehicles = [];
      if (
        this.searchDictMakeModel.year &&
        this.searchDictMakeModel.make &&
        this.searchDictMakeModel.model &&
        this.searchDictMakeModel.mileage
      ) {
        if (this.searchDictMakeModel.year.toString().length == 4) {
          this.loadingVehicles = true;
          searchAGWithFacets(this.calculateMakeModelExtras())
            .then(response => {
              this.loadingVehicles = false;
              this.vehicles = response;
            })
            .catch(error => {
              this.loadingVehicles = false;
              this.fetchError = error;
            });
        }
      }
    },
    retryRequest: function () {
      requestAGFacet(this.retryFetch, this.calculateMakeModelExtras())
        .then(response => {
          this[this.retryFetchArray] = response;
          this.retryFetch = "";
          this.retryFetchArray = "";
        })
        .catch(error => {
          console.log(error);
        });
    },
    selectedVehicle: function (vehicle) {
      this.$emit("requestGlobalMarketID", { vehicle: vehicle, mileage: this.searchDictMakeModel.mileage });
    },
    cancelClicked: function () {
      this.$emit("cancel");
    }
  },
  watch: {
    "searchDictMakeModel.make": function (newVal) {
      this.searchDictMakeModel.model = "";
      this.searchDictMakeModel.badge = "";
      this.searchDictMakeModel.series = "";
      this.searchDictMakeModel.transmission = "";
      this.searchDictMakeModel.body_type = "";

      this.facetModels = [];
      this.facetBadges = [];
      this.facetSeriess = [];
      this.facetTransmission = [];
      this.facetBodyTypes = [];
      if (newVal) {
        requestAGFacet("model", this.calculateMakeModelExtras())
          .then(response => {
            this.facetModels = response;
          })
          .catch(error => {
            this.retryFetch = "model";
            this.retryFetchArray = "facetModels";
            console.log(error);
          });
      }
    },
    "searchDictMakeModel.model": function (newVal) {
      this.searchDictMakeModel.badge = "";
      this.searchDictMakeModel.series = "";
      this.searchDictMakeModel.transmission = "";
      this.searchDictMakeModel.body_type = "";

      this.facetBadges = [];
      this.facetSeriess = [];
      this.facetTransmission = [];
      this.facetBodyTypes = [];
      if (newVal) {
        this.searchForVehicles();
        requestAGFacet("badge", this.calculateMakeModelExtras())
          .then(response => {
            this.facetBadges = response;
          })
          .catch(error => {
            this.retryFetch = "badge";
            this.retryFetchArray = "facetBadges";
            console.log(this.retryFetch);
            console.log(error);
          });
      }
    },
    "searchDictMakeModel.badge": function (newVal) {
      this.searchDictMakeModel.series = "";
      this.searchDictMakeModel.transmission = "";
      this.searchDictMakeModel.body_type = "";

      this.facetSeriess = [];
      this.facetTransmission = [];
      this.facetBodyTypes = [];
      if (newVal) {
        this.searchForVehicles();
        requestAGFacet("series", this.calculateMakeModelExtras())
          .then(response => {
            this.facetSeriess = response;
          })
          .catch(error => {
            this.retryFetch = "series";
            this.retryFetchArray = "facetSeriess";
            console.log(error);
          });
      }
    },
    "searchDictMakeModel.series": function (newVal) {
      this.searchDictMakeModel.transmission = "";
      this.searchDictMakeModel.body_type = "";

      this.transmission = "";
      this.body_type = "";

      this.facetTransmission = [];
      this.facetBodyTypes = [];
      if (newVal) {
        this.searchForVehicles();
        requestAGFacet("transmission", this.calculateMakeModelExtras())
          .then(response => {
            this.facetTransmission = response;
          })
          .catch(error => {
            this.retryFetch = "transmission";
            this.retryFetchArray = "facetTransmission";
            console.log(error);
          });
      }
    },
    "searchDictMakeModel.transmission": function (newVal) {
      this.searchDictMakeModel.body_type = "";

      this.facetBodyTypes = [];
      if (newVal) {
        this.searchForVehicles();
        requestAGFacet("body_type", this.calculateMakeModelExtras())
          .then(response => {
            this.facetBodyTypes = response;
          })
          .catch(error => {
            this.retryFetch = "body_type";
            this.retryFetchArray = "facetBodyTypes";
            console.log(error);
          });
      }
    },
    "searchDictMakeModel.body_type": function () {
      this.searchForVehicles();
    },
    "searchDictMakeModel.year": function () {
      this.searchForVehicles();
    },
    "searchDictMakeModel.mileage": function (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.searchForVehicles();
      }
    }
  },
  mounted: function () {
    if (this.inputType == 2 && this.facetMakes.length <= 0) {
      requestAGFacet("make", "")
        .then(response => {
          this.facetMakes = response;
        })
        .catch(error => {
          console.log(error);
          this.retryFetch = "make";
          this.retryFetchArray = "facetMakes";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
